<template>

    <div class="p-d-flex p-flex-column container page">
        <div class="p-mb-2">
          <div class="p-d-flex p-jc-center">
            <TitleP title="Simulador de TPP" style="line-height: 60px;"/>
          </div>
        </div>

        <div v-if="showThemeSelection">
          <h4 style="display: inline-block;">Tema:</h4>
          <Dropdown 
            class="dropdownP"
            v-model="selectedTheme"
            :options="themes"
            optionLabel="name"
            optionValue="code"
            placeholder="Selecione um tema"
            @change="changeTheme()"
          />
        </div>

        <div class="p-mb-2">
          <div class="p-d-flex p-flex-column container" style="display:flex">
            <h4>Digitar Payload</h4>
            <Textarea class="textarea" v-model="jwtPayload" rows="10" cols="120" style="flex:1" placeholder="payload jwt obtido ao criar o consentimento" />
          </div>
        </div>
        <div class="p-d-flex p-flex-column">
          <div class="p-field-checkbox" style="margin: 10px 0px 10px 10px;">
            <Checkbox id="simulateTimout21" class="checkP" v-model="simulateTimoutCT21" :binary="true"/>
            <label for="simulateTimout21">Simular Timeout CT-21 – FB_TIME OUT PARCEIROS</label>
          </div>
          <div class="p-field-checkbox" style="margin: 10px 0px 10px 10px;">
            <Checkbox id="simulateTimout23" class="checkP" v-model="simulateTimoutCT23" :binary="true"/>
            <label for="simulateTimout23">Simular Timeout CT-23 – FB TIME OUT TECBAN</label>
          </div>
          <div class="p-field-checkbox" style="margin: 10px 0px 10px 10px;">
            <Checkbox id="simulateTimeoutConsultConsents" class="checkP" v-model="simulateTimeoutConsultConsents" :binary="true"/>
            <label for="simulateTimeoutConsultConsents">Simular timeout - consulta de consentimentos cliente (ao entrar)</label>
          </div>
          <div class="container">
            <Button class="buttonP" label="Autenticar" type="button" @click="getJwt()"/>
          </div>
        </div>
           
        <div class="p-d-flex p-jc-center">
          <div class="p-d-flex p-flex-column container" style="display:flex">
            <ul class="menu">
              <li v-for="route in routes" :key="route.route">
                <a v-bind:href="route.route">{{ route.route }}</a>
              </li>
            </ul>  
          </div>
        </div>
      <ExceptionModal
       ref="exceptionWarnig"
       tipo='error'
       v-bind:messageErr="erroTitle"
       v-bind:detailErr="errorDetail"
      />
    </div>

</template>

<script>
import jwt from 'jwt-simple';
import { AppRoutes, getTheme, getWithExpiry, setWithExpiry, setWithoutExpiry, getWithoutExpiry } from '@/util/util.js';

function formPostJweSubmit(serviceUrlPortalC, jwe){

  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${serviceUrlPortalC}/consent/jwe`;

  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = 'JWE';
  hiddenField.value = jwe;

  form.appendChild(hiddenField);

  document.body.appendChild(form);
  form.submit();
}

export default {
    data()  {
      return {
        
        showThemeSelection: false,

        selectedTheme: 'Tecban',
        themes: [
          {name: 'Basa', code: 'amz'},
          {name: 'BrasilCard', code: 'bcd'},
          {name: 'BS2', code: 'bs2'},
          {name: 'CCB', code: 'ccb'},
          {name: 'CredSIS', code: 'cds'},
          {name: 'Lets', code: 'let'},
          {name: 'Maxima', code: 'bmx'},
          {name: 'Ourinvest', code: 'ori'},
          {name: 'Tecban', code: 'exb'},
          {name: 'Voiter', code: 'voi'}
        ],

        simulateTimoutCT21: false,
        simulateTimoutCT23: false,
        simulateTimeoutConsultConsents: false,
        opts:'payments',
        iconMeusCompartilhamentos: 'meus-compartilhamentos',
        iconMeusConsentimentos: 'meus-consentimentos',
        aboutRoute: 'MyConsents',
        doubts: 'faq',
        contactUs:'contactUs',
        action2: 'contactUs',
        request: '',
        url: '',
        urlHom:'',
        consultConsent:`{
    "data": {

        "loggedUser": {
            "document": {
                "identification": "12345678901",
                "rel": "CPF"
            }
        },
        "businessEntity": {
            "document": {
                "identification": "11111111111111",
                "rel": "CNPJ"
            }
        },
        "permissions": [
            "ACCOUNTS_READ",
            "ACCOUNTS_OVERDRAFT_LIMITS_READ",
            "RESOURCES_READ",
            "ACCOUNTS_BALANCES_READ",
            "CREDIT_CARDS_ACCOUNTS_READ",
            "CREDIT_CARDS_ACCOUNTS_BILLS_READ",
            "CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ",
            "CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ",
            "CREDIT_CARDS_ACCOUNTS_LIMITS_READ",
            "LOANS_READ",
            "LOANS_SCHEDULED_INSTALMENTS_READ",
            "LOANS_PAYMENTS_READ",
            "LOANS_WARRANTIES_READ",
            "CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ",
            "CUSTOMERS_PERSONAL_ADITTIONALINFO_READ",
            "CUSTOMERS_PERSONAL_ADITTIONALINFO_READ",
            "CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ",
            "CUSTOMERS_BUSINESS_ADITTIONALINFO_READ",
            "CUSTOMERS_BUSINESS_ADITTIONALINFO_READ"
  
            ],
        "expirationDateTime": "2025-05-21T08:30:00Z",
        "transactionFromDateTime": "2016-01-01T10:40:00Z",
        "transactionToDateTime": "2025-12-31T10:40:00Z"
        
    }
}`,
        paymentConsent: `{
  "data": {
    "loggedUser": {
        "document": {
            "identification": "12345678901",
            "rel": "CPF"
        }
    },
    "businessEntity": {
      "document": {
        "identification": "11111111111111",
        "rel": "CNPJ"
      }
    },
    "creditor": {
      "personType": "PESSOA_NATURAL",
      "cpfCnpj": "58764789000137",
      "name": "Marco Antonio de Brito"
    },
    "payment": {
      "type": "PIX",
      "date": "2021-01-01",
      "currency": "BRL",
      "amount": "100000.12"
    }
  }
}`,
        jwtPayload:'',
        jwtEncoded:'',
        authDisabled: true,
        routes: [],
        erroTitle: 'Erro',
        detailErr: 'Erro ao fazer o processo de autenticação',
      }
    },
    methods:  {

      changeTheme() {

        if (this.selectedTheme == null){
          return;
        }
        else{
          setWithoutExpiry('theme', this.selectedTheme);
          // refresh page
          this.$router.go();
        }

      },
      createConsent() {
        var baseUrl = process.env.VUE_APP_CONSENTS;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: this.paymentConsent,
          redirect: 'follow'
        };

        if(this.opts == 'consents'){
          requestOptions.body = this.consultConsent
        }

        fetch(`${baseUrl}/tpp/token/${this.opts}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            this.jwtPayload = result;
            this.authDisabled = false;
          }).catch(error => console.log('error', error));
      },
      getJwt() {

        this.$root.$refs.loadingModal.enable();

        var baseUrlLocal = process.env.VUE_APP_CONSENTS_LOCAL;
        var baseUrl = process.env.VUE_APP_CONSENTS;
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if(this.jwtPayload != null && this.jwtPayload) {
          var jwtPayloadParsed = JSON.parse(this.jwtPayload);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(jwtPayloadParsed),
            redirect: 'follow'
          };

          fetch(`${baseUrl}/tpp/jwt`, requestOptions)
            .then(response => response.text())
            .then( (jwe) => {

              sessionStorage.setItem("TIMEOUT_SIMULATION_CT21", this.simulateTimoutCT21);
              sessionStorage.setItem("TIMEOUT_SIMULATION_CT23", this.simulateTimoutCT23);
              sessionStorage.setItem("TIMEOUT_SIMULATION_CONSULT_CONSENTS", this.simulateTimeoutConsultConsents);

              this.$root.$refs.loadingModal.disable();

              let d = new Date();
              d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
              const expires = "expires=" + d.toUTCString();
              document.cookie =
                "JWE=" + jwtPayloadParsed.jti + ";" + expires + ";path=/";

              // dev or hml
              if (process.env.VUE_APP_ENV.toLowerCase().trim() == 'dev' || process.env.VUE_APP_ENV.toLowerCase().trim() == 'hml')
                formPostJweSubmit(baseUrl,jwe);
              // local
              else
                formPostJweSubmit(baseUrlLocal,jwe);
            }).catch(error => {
              this.$refs.exceptionWarnig.enable();
            });
        }
        else {
          this.$root.$refs.loadingModal.disable();
          this.$refs.exceptionWarnig.enable();
        }
      }
    },
    created(){
      this.showThemeSelection = process.env.VUE_APP_IF_NAME.toLowerCase().trim() == 'qa';
      this.selectedTheme = getTheme();
    },
    mounted(){
      this.$root.$refs.mainNavbar.hideGoBackButton();
    }
}
</script>

<style scoped>
.page{
  background-color: var(--page-background-color);
}
.container {
  padding: 1em;
}
.box2 {
  background: var(--page-background-color);
  box-shadow: var(--shadow-default);
  border-radius: 8px;
  min-width: 312px;
  min-height: 59px;
}
.box2 :active {
  border: none;
}
.box-text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
}
.box2-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  padding:1em;
}
hr.solid {
  border-top: 3px solid #bbb;
}

.wrapper {
  display: inline;
}
.radioPLabel {
  font-size: 18px;
}

.labelP{
  vertical-align: middle;
  color: var(--checkbox-border-color);
  margin-left: 5px;
}
h4{
  font-size: 20px;
  color: var(--checkbox-fill-color);
}
.textarea{
  border: 2px solid var(--checkbox-border-color);
  border-radius: 8px;
}
.textarea:hover, .textarea:checked {
  box-shadow: var(--shadow-default) !important;
}

.buttonP{
  background-color: var(--button-confirmconsent-background-color);
  color: var(--button-confirmconsent-text-color);
  line-height: 24px;
  font-weight: bold;
  border: var(--button-confirmconsent-border);
  border-radius: 8px;
  display: block;
  box-shadow: var(--button-confirmconsent-shadow);
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  height: 56px;
}
.buttonP:focus, .buttonP:active {
  outline: none !important;
  box-shadow: var(--button-confirmconsent-shadow);
  color: var(--button-confirmconsent-text-color) !important;
}
.buttonP:hover {
  background: var(--button-confirmconsent-background-hover-color) !important;
  color: var(--button-confirmconsent-text-color) !important;
  border: var(--button-confirmconsent-border) !important;
}

.dropdownP{
  margin-left: 20px;
  padding: 0px 10px 0px 10px;
}
</style>          